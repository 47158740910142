<script>
  import {slide} from 'svelte/transition';
  import CloseIcon from './icon/CloseIcon.svelte';

  export let text = '';
  export let type = '';
</script>

<div class="flex items-center relative z-[100]">
  <!-- TBD: This is causing a reactivity bug in some cases - where notification is immediateyl set to null after assignment -->
  <!-- use:clickOutside={() => ($notifications = [])} -->
  <div
    class="w-full flex items-center justify-center px-4 {type ===
    'success'
      ? 'bg-green-500 !text-charcoal'
      : type === 'error'
      ? 'bg-red-500'
      : ''} text-sm text-white h-9 text-center"
    transition:slide
  >
    {text}
  </div>

  <div class="absolute right-8 inline-flex items-center justify-end">
    <button
      aria-label="Close"
      on:click
      class="group"
    >
      <CloseIcon
        cssClass={type === 'success'
          ? '!text-charcoal'
          : '!text-white'}
      />
    </button>
  </div>
</div>
