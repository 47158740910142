<script>
  import {fly, fade} from 'svelte/transition';
  import {createEventDispatcher} from 'svelte';
  import CloseIcon from '../icon/CloseIcon.svelte';

  const dispatch = createEventDispatcher();

  export let ajaxRequest = false;
  export let saveDisabled;

  const handleEscKeyClose = (e) => {
    //27 escpae key
    if (e.keyCode === 27) {
      dispatch('cancel');
    }
  };
</script>

<svelte:window on:keydown={handleEscKeyClose} />

<div
  data-test="unsaved-changes-modal"
  class="fixed z-10 inset-0 overflow-y-auto"
  aria-labelledby="unsaved-changes-modal"
  role="dialog"
  aria-modal="true"
>
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    transition:fly|local={{y: 20}}
  >
    <div
      on:click={() => dispatch('cancel')}
      class="fixed inset-0 bg-black bg-opacity-60 transition-opacity"
      aria-hidden="true"
      in:fade|local={{duration: 200}}
      out:fade|local={{duration: 100}}
    />

    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >

    <div
      class="inline-block align-bottom bg-white dark:bg-[#151515] dark:border dark:border-[#808080] text-charcoal dark:text-[#d4d4d4] rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
    >
      <button
        aria-label="Close"
        on:click={() => dispatch('cancel')}
        class="absolute right-5 top-5 group"
      >
        <CloseIcon />
      </button>

      <div class="p-8 flex flex-col">
        <h3 class="text-lg text-center leading-6 font-medium" id="modal-title">
          You have unsaved changes!
        </h3>
        <div class="flex flex-col md:flex-row items-center justify-around mt-6">
          <!-- svelte-ignore a11y-autofocus -->
          <button
            data-test="discard-changes-btn"
            autofocus
            on:click={() => dispatch('discard')}
            class="w-full md:w-2/5 btn-danger h-9">Discard changes</button
          >
          <button
            data-test="save-changes-btn"
            on:click={() => dispatch('save')}
            class="w-full mt-4 md:mt-0 md:w-2/5 h-9 rounded-md font-medium btn-background"
            disabled={saveDisabled && saveDisabled()}
          >
            {#if ajaxRequest}
              <svg
                class="animate-spin h-5 w-5 block mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                />
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            {:else}
              Save changes
            {/if}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
