<script>
  import {fly, fade} from 'svelte/transition';
  import {createEventDispatcher} from 'svelte';
  import CloseIcon from '../icon/CloseIcon.svelte';

  export let appName = '';
  export let redirectURI = '';

  export let disableAddDevRedirectURI = false;
  export let disableAddProdRedirectURI = false;

  let devRedirectSpinner = false;
  let prodRedirectSpinner = false;

  const dispatch = createEventDispatcher();

  const handleEscKeyClose = (e) => {
    //27 escpae key
    if (e.keyCode === 27) {
      dispatch('close');
    }
  };

  let copiedTooltip = false;
  async function copy() {
    copiedTooltip = true;
    await navigator.clipboard.writeText(redirectURI);

    setTimeout(() => {
      copiedTooltip = false;
    }, 1000);
  }
</script>

<svelte:window on:keydown={handleEscKeyClose} />
<div class="fixed z-10 inset-0 overflow-y-auto" role="dialog" aria-modal="true">
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    transition:fly|local={{y: 20}}
  >
    <div
      class="fixed inset-0 bg-black bg-opacity-80 transition-opacity"
      aria-hidden="true"
      in:fade|local={{duration: 200}}
      out:fade|local={{duration: 100}}
    />

    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >

    <div
      class="inline-block align-bottom bg-white dark:bg-[#151515] dark:border dark:border-[#808080] text-charcoal dark:text-[#d4d4d4] rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
    >
      <button
        aria-label="Close"
        on:click={() => dispatch('close')}
        class="absolute right-5 top-4 group"
      >
        <CloseIcon />
      </button>

      <div class="p-8 flex flex-col">
        <h3 class="text-lg text-center leading-6" id="modal-title">
          {#if disableAddDevRedirectURI && disableAddProdRedirectURI}
            The following Redirect URI is configured for both<br />
            Production and Development Redirect URIs for
          {:else if disableAddDevRedirectURI}
            The following Redirect URI is configured for<br />
            a Development Redirect URI for
          {:else if disableAddProdRedirectURI}
            The following Redirect URI is configured for<br />
            a Production Redirect URI for
          {:else}
            The following Redirect URI is not configured for
          {/if}
          <br /><span class="font-bold">{appName}</span>
        </h3>
        <!-- svelte-ignore a11y-autofocus -->
        <button
          type="button"
          autofocus
          class="group flex items-center justify-center relative btn-background-border-none mt-4 h-auto py-1"
          on:click={copy}
        >
          <span class="px-10" style="word-break: break-word;"
            >{redirectURI}</span
          >

          <div class="inline-flex items-center absolute right-2" id="client-id">
            <div class="relative inline-flex items-center">
              {#if copiedTooltip}
                <span
                  class="absolute bottom-6 -left-5 text-sm bg-green-500 p-1 rounded-md text-charcoal"
                  transition:fly={{y: 10}}
                >
                  Copied!
                </span>
              {/if}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3"
                  d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
            </div>
          </div>
        </button>
        <div class="flex flex-col items-center justify-between mt-6 space-y-4">
          {#if disableAddDevRedirectURI && disableAddProdRedirectURI}
            <!-- svelte-ignore a11y-autofocus -->
            <button
              on:click={() => dispatch('close')}
              autofocus
              class="px-4 btn-background h-auto sm:h-9 w-3/4"
            >
              Continue
            </button>
          {:else}
            <!-- svelte-ignore a11y-autofocus -->
            <button
              on:click={() => {
                devRedirectSpinner = true; //TBD: handle failure
                dispatch('addToDevRedirectURI');
              }}
              autofocus
              class="px-4 btn-background h-auto sm:h-9 w-3/4"
              disabled={devRedirectSpinner || disableAddDevRedirectURI}
            >
              {#if devRedirectSpinner}
                <svg
                  class="animate-spin h-5 w-5 block mx-auto text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  />
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              {:else}
                Add to Development Redirect URIs
              {/if}
            </button>
            <button
              on:click={() => {
                prodRedirectSpinner = true; //TBD: handle failure
                dispatch('addToProdRedirectURI');
              }}
              class="px-4 btn-background h-auto sm:h-9 w-3/4"
              disabled={prodRedirectSpinner || disableAddProdRedirectURI}
            >
              {#if prodRedirectSpinner}
                <svg
                  class="animate-spin h-5 w-5 block mx-auto text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  />
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              {:else}
                Add to Production Redirect URIs
              {/if}
            </button>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>
