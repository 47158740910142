<script>
  import {fly, fade} from 'svelte/transition';
  import {addNotification, data} from '../../stores.js';
  import {createEventDispatcher} from 'svelte';
  import {getPublisher, postPublisher} from '../../utils/api-calls.js';
  import LargeButton from '../button/LargeButton.svelte';
  import { CloseIcon } from '../icon';
  import {UseUUIDCheckbox} from '../input';

  const dispatch = createEventDispatcher();
  let pubValue = '';
  let createPublisherAjax = false;
  let useUUID = false;

  async function createPublisher() {
    try {
      createPublisherAjax = true;
      const postPublisherRes = await postPublisher({name: pubValue, useUUID});
      if(!postPublisherRes?.profile?.id) {
        throw new Error('Unexpected response from API');
      }
      const publisher = await getPublisher(postPublisherRes.profile.id);
      $data.publishers = [...$data.publishers, {
        ...publisher.profile,
        role: publisher.role //publishers array at root of API response has role
      }]; 
      $data.currentPublisher = publisher;
      dispatch('success', postPublisherRes.profile);
      dispatch('close');
      } catch (err) {
        console.error(err);
        dispatch('close');
        addNotification({
          type: 'error',
          text: 'Something went wrong'
        });
        createPublisherAjax = false;
    }
  }

  const handleEscKeyClose = (e) => {
    //27 escpae key
    if (e.keyCode === 27) {
      dispatch('close');
    }
  };
</script>

<svelte:window on:keydown={handleEscKeyClose} />

<div
  data-test="create-publisher-modal"
  class="fixed z-10 inset-0 overflow-y-auto"
  aria-label="Create Publisher"
  role="dialog"
  aria-modal="true"
  transition:fly|local={{y: 20}}
>
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div
      on:click={() => dispatch('close')}
      class="fixed inset-0 bg-black bg-opacity-80 transition-opacity"
      aria-hidden="true"
      in:fade|local={{duration: 200}}
      out:fade|local={{duration: 100}}
    />

    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >

    <div
      class="inline-block align-bottom bg-white dark:bg-[#151515] dark:border dark:border-[#808080] rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
    >
      <button
        data-test="close-create-publisher-modal-btn"
        aria-label="Close"
        on:click={() => dispatch('close')}
        class="absolute right-5 top-5 group"
      >
        <CloseIcon />
      </button>

      <form
        class="p-8 flex flex-col"
        on:submit|preventDefault={createPublisher}
      >
        <label for="pub_name" class="block font-semibold">Publisher Name</label>

        <!-- svelte-ignore a11y-autofocus -->
        <input
          id="pub_name"
          type="text"
          name="pub_name"
          bind:value={pubValue}
          autofocus
          class="form-text mt-2 rounded-md h-9 px-3 bg-transparent"
        />

        <div class="mt-4 mx-3">
          <UseUUIDCheckbox bind:checked={useUUID} />
        </div>

        <LargeButton
          type="submit"
          value="Create Publisher"
          disabled={pubValue.length < 1 || createPublisherAjax}
          loading={createPublisherAjax}
        />
      </form>
    </div>
  </div>
</div>
