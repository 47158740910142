<script>
  export let cssClass = '';
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  class="h-5 w-5 stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3 text-charcoal dark:text-[#d4d4d4] {cssClass}"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M6 18L18 6M6 6l12 12"
  />
</svg>
