<script>
  import {slide} from 'svelte/transition';
  import {checkURIValidity} from '../../utils/helper.js';

  /**
   * Possible name props
   * Name
   * Terms of Service
   * Privacy Policy
   */

  export let edited = false;
  export let autofocus = false;
  export let id = '';
  export let name = '';
  export let value = '';
  export let placeholder = ''
  export let readonly = false;

  // TODO: Refactor needed here, calling checkURIValidity function twice (here and on page)
  $: isValidURI =
    value &&
    ['Privacy Policy', 'Terms of Service'].includes(name) &&
    checkURIValidity(value);
</script>

<div class="w-full flex flex-col">
  <label for={id} class="px-4 font-medium text-base">{name}</label>
  <div class="py-1.5 flex flex-col" class:bg-green-400={value && edited}>
    <!-- svelte-ignore a11y-autofocus -->
    <input
      {id}
      name={id}
      {autofocus}
      {placeholder}
      type="text"
      {readonly}
      bind:value
      class="h-9 px-3 ml-8 mr-4 rounded-md dark:placeholder-[#808080] dark:placeholder-opacity-80 bg-white dark:bg-[#151515]"
    />

    {#if !value}
      <span
        data-test="error-text"
        class="px-8 mt-2 text-sm text-red-500"
        transition:slide|local>Name cannot be blank</span
      >
    {/if}
  </div>
</div>
