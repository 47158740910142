import {writable} from 'svelte/store';

export const data = writable(null);

export const notifications = writable([]);
export const addNotification = ({text, type}) => {
    notifications.update(n => {
        n.push({text, type});
        return n;
    });
}

export const ajaxRequest = writable(false);
export const showLoginPage = writable(false);
