import {showLoginPage, addNotification} from '../stores.js';

async function send({method, path, data}) {
  const opts = {method, headers: {}};

  if (data) {
    opts.headers['Content-Type'] = 'application/json';
    opts.body = JSON.stringify(data);
  }

  //TODO call mock/token
  // let mock_token =
  //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwOi8vaGVsbG8uY29vcDo3MDAyLyIsImF1ZCI6ImhlbGxvX2NvbnNvbGUiLCJzdWIiOiJ1c2VyXzEiLCJub25jZSI6Im5vIG5vbmNlIHByb3ZpZGVkIiwic2NvcGUiOiJuYW1lIG5pY2tuYW1lIHBpY3R1cmUgZW1haWwgY29uc29sZSIsIm5hbWUiOiJKb2huIFNtaXRoIiwicGljdHVyZSI6Imh0dHBzOi8vY2RuLmhlbGxvLmNvb3AvbW9jay9qb2huLXNtaXRoLWdvb2dsZS5qcGVnIiwiZW1haWwiOiJqb2huLnNtaXRoQGdtYWlsLmNvbSIsImlhdCI6MTY0NDQxNzI1NywiZXhwIjoxNjQ0NDIwODU3fQ.EuuwbxCe8PUPnfieLd6QJ0Tv_0DShe-GngG2nJCeDMs';
  // opts.headers['Authorization'] = `Bearer ${
  //   mock_token || sessionStorage.getItem('token')
  // }`;

  opts.headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;

  return fetch(window.endpoints.api + '/api/v1' + path, opts)
    .then((res) => {
      if (!res.ok) throw res;
      return res.json();
    })
    .catch(async (err) => {
      if (err instanceof TypeError) {
        addNotification({
          text: 'Network error, please try again',
          type: 'error'
        });

        throw err;
      }
      if (err.status === 401) {
        addNotification({
          text: 'Session expired',
          type: 'error'
        });
        sessionStorage.removeItem('token');
        // globalData.set(null);
        showLoginPage.set(true);
      }
      if (err.status >= 500) {
        addNotification.set({
          text: 'Something went wrong',
          type: 'error'
        });
      }

      throw err;
    });
}

export function get(path) {
  return send({method: 'GET', path});
}

export function del(path) {
  return send({method: 'DELETE', path});
}

export function post(path, data) {
  return send({method: 'POST', path, data});
}

export function put(path, data) {
  return send({method: 'PUT', path, data});
}
