<script>
  import {onMount} from 'svelte';

  export let login = () => {};
  export let loginAjax = false;
  let darkMode = true;

  onMount(() => {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      darkMode = true;
    } else {
      darkMode = false;
    }

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        if (event.matches) {
          darkMode = true;
        } else {
          darkMode = false;
        }
      });
  });
</script>

<main class="flex-1 flex flex-col overflow-y-auto">
  <div class="px-4 max-w-2xl mx-auto py-12 text-center flex-1">
    <div class="my-4 flex flex-col items-center">
      <h1 class="text-2xl font-medium leading-normal">
        Welcome to the<br />Hellō Developer Console
      </h1>
      <button
        on:click={login}
        data-test="continue-btn"
        class="my-10 hello-btn-black-and-static"
        class:hello-btn-hover-flare={darkMode}
        class:hello-btn-loader={loginAjax}
        disabled={loginAjax}>ō&nbsp;&nbsp;&nbsp; Continue with Hellō</button
      >
    </div>

    <p class="opacity-80">
      Hellō is an OpenID Connect Provider that simplifies user registration and
      login, allowing you to provide all the choices your user's may want in
      hours instead of days or weeks.
    </p>
  </div>
</main>

<wc-footer />
