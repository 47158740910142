<script>
  import {fly, fade} from 'svelte/transition';
  import {data} from '../../stores.js';
  import {createEventDispatcher} from 'svelte';
  import {postApplication} from '../../utils/api-calls.js';
  import LargeButton from '../button/LargeButton.svelte';
  import CloseIcon from '../icon/CloseIcon.svelte';

  const dispatch = createEventDispatcher();
  let appValue = '';
  let createApplicationAjax = false;

  async function createApplication() {
    const publisher_id = $data.currentPublisher.profile.id;
    if (!publisher_id) return;
    try {
      createApplicationAjax = true;
      const body = {
        name: appValue,
        tos_uri: null,
        pp_uri: null,
        image_uri: null,
        web: {
          dev: {
            localhost: true,
            '127.0.0.1': true,
            wilcard_domain: false,
            redirect_uris: []
          },
          prod: {
            redirect_uris: []
          }
        },
        createdBy: 'console'
      };
      const res = await postApplication(publisher_id, body);
      /**
       * API does not send createdAt property on POST call.
       * Added here for sorting to work properly.
       */
      res.createdAt = new Date().toISOString();

      if (!$data.currentPublisher.applications) {
        $data.currentPublisher.applications = [];
      }

      $data.currentPublisher.applications = [
        ...$data.currentPublisher.applications,
        res
      ];
      dispatch('success', res);
      dispatch('close');
    } catch (err) {
      console.error(err);
      createApplicationAjax = false;
    }
  }

  const handleEscKeyClose = (e) => {
    //27 escpae key
    if (e.keyCode === 27) {
      dispatch('close');
    }
  };
</script>

<svelte:window on:keydown={handleEscKeyClose} />

<div
  data-test="create-application-modal"
  class="fixed z-10 inset-0 overflow-y-auto"
  aria-label="Create application"
  role="dialog"
  aria-modal="true"
  transition:fly|local={{y: 20}}
>
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div
      on:click={() => dispatch('close')}
      class="fixed inset-0 bg-black bg-opacity-80 transition-opacity"
      aria-hidden="true"
      in:fade|local={{duration: 200}}
      out:fade|local={{duration: 100}}
    />

    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >

    <div
      class="inline-block align-bottom bg-white dark:bg-[#151515] dark:border dark:border-[#808080] rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
    >
      <button
        data-test="close-create-application-modal-btn"
        aria-label="Close"
        on:click={() => dispatch('close')}
        class="absolute right-5 top-5 group"
      >
        <CloseIcon />
      </button>

      <form
        class="p-8 flex flex-col"
        on:submit|preventDefault={createApplication}
      >
        <label for="app_name" class="block font-semibold"
          >Application Name</label
        >

        <!-- svelte-ignore a11y-autofocus -->
        <input
          id="app_name"
          type="text"
          name="app_name"
          bind:value={appValue}
          autofocus
          class="form-text mt-2 rounded-md h-9 px-3 bg-transparent"
        />
        <LargeButton
          type="submit"
          value="Create Application"
          disabled={appValue.length < 1 || createApplicationAjax}
          loading={createApplicationAjax}
        />
      </form>
    </div>
  </div>
</div>
