<script>
    import {HelpIcon} from "../icon";
    export let checked = false;
</script>

<div class="inline-flex items-center">
    <input
        id="use-uuid"
        bind:checked
        type="checkbox"
        class="form-checkbox border-charcoal dark:border-[#808080] shadow-none rounded-sm text-charcoal dark:bg-transparent dark:text-[#808080] focus-visible:ring-0 disabled:opacity-80 !ring-offset-0"
/>
    <label for="use-uuid" class="ml-2">Use legacy UUIDs</label>
    <HelpIcon
        css="ml-1"
        content="Receive legacy UUIDs instead of the nanoid format xxx_01234567abcdefghABCDEFGH_XXX"
        placement="right"
    />
</div>