<script>
  import {fly, fade} from 'svelte/transition';
  import {createEventDispatcher} from 'svelte';
  import {postClientSecret} from '../../utils/api-calls.js';
  import {nanoid} from 'nanoid';
  import shajs from 'sha.js';
  import CloseIcon from '../icon/CloseIcon.svelte';

  const dispatch = createEventDispatcher();
  export let pub_id;
  export let app_id;
  let copiedTooltip = false;
  let createClientSecretAjax = false;
  const secret = nanoid();
  const salt = crypto.randomUUID();
  const hash = hashFunction(secret, salt);

  function hashFunction(secret, salt) {
    const hash = shajs('sha256').update(secret).update(salt).digest('hex');
    return hash;
  }

  async function createClientSecret() {
    try {
      createClientSecretAjax = true;
      const res = await postClientSecret({
        pub_id,
        app_id,
        hash,
        salt
      });
      const {secrets} = res;
      dispatch('success', secrets);
      dispatch('close');
    } catch (err) {
      console.error(err);
      createClientSecretAjax = false;
    }
  }

  async function copy() {
    copiedTooltip = true;
    await navigator.clipboard.writeText(secret);

    setTimeout(() => {
      copiedTooltip = false;
    }, 1000);
  }

  const handleEscKeyClose = (e) => {
    //27 escpae key
    if (e.keyCode === 27) {
      dispatch('close');
    }
  };
</script>

<svelte:window on:keydown={handleEscKeyClose} />

<div
  data-test="create-client-secret-modal"
  class="fixed z-10 inset-0 overflow-y-auto"
  aria-labelledby="create-client-secret-modal"
  role="dialog"
  aria-modal="true"
  transition:fly|local={{y: 20}}
>
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div
      on:click={() => dispatch('close')}
      class="fixed inset-0 bg-black bg-opacity-80 transition-opacity"
      aria-hidden="true"
      in:fade|local={{duration: 200}}
      out:fade|local={{duration: 100}}
    />

    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >

    <div
      class="inline-block align-bottom bg-white dark:bg-[#151515] dark:border dark:border-[#808080] rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
    >
      <button
        aria-label="Close"
        on:click={() => dispatch('close')}
        class="absolute right-5 top-4 group"
      >
        <CloseIcon />
      </button>

      <form
        class="p-4 flex flex-col"
        on:submit|preventDefault={createClientSecret}
      >
        <label for="app_name" class="block font-semibold text-center mb-3"
          >New Client Secret</label
        >

        <!-- svelte-ignore a11y-autofocus -->
        <button
          type="button"
          autofocus
          class="h-9 group flex items-center justify-center relative mt-1 btn-background-border-none"
          on:click={copy}
        >
          <span>
            {secret}
          </span>

          <div class="inline-flex items-center absolute right-2" id="client-id">
            <div class="relative inline-flex items-center">
              {#if copiedTooltip}
                <span
                  class="absolute bottom-6 -left-5 text-sm bg-green-500 p-1 rounded-md text-charcoal"
                  transition:fly={{y: 10}}
                >
                  Copied!
                </span>
              {/if}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3"
                  d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
            </div>
          </div>
        </button>

        <p class="text-center mt-4">
          Copy and store your new client secret.<br />This secret will only be
          shown this one time.
        </p>

        <button
          data-test="confirm-create-client-secret-btn"
          disabled={createClientSecretAjax}
          type="submit"
          class="btn-background inline-flex items-center justify-center px-8 !w-28 !h-9 mx-auto mt-6"
        >
          {#if createClientSecretAjax}
            <svg
              class="animate-spin h-5 w-5 block mx-auto text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              />
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          {:else}
            OK
          {/if}
        </button>
      </form>
    </div>
  </div>
</div>
