import './index.css';
import App from './App.svelte';
import {Buffer} from 'buffer';
import {endpoints} from './utils/helper.js';

window.endpoints = endpoints;
globalThis.Buffer = Buffer;

const app = new App({
  target: document.getElementById('app')
});

export default app;
