<script>
  export let disabled = false;
  export let manage = false;
</script>

<button
  on:click
  {disabled}
  class="h-7 px-4"
  class:btn-border={manage}
  class:btn-background={!manage}
>
  {manage ? 'Manage all outstanding invites' : 'Invite'}
</button>
