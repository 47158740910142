<script>
  import {fly, fade} from 'svelte/transition';
  import {createEventDispatcher} from 'svelte';

  export let clientId = '';
  const dispatch = createEventDispatcher();

  const handleEscKeyClose = (e) => {
    //27 escpae key
    if (e.keyCode === 27) {
      dispatch('ok');
    }
  };

  let copiedTooltip = false;
  async function copy() {
    copiedTooltip = true;
    await navigator.clipboard.writeText(clientId);

    setTimeout(() => {
      copiedTooltip = false;
    }, 1000);
  }
</script>

<svelte:window on:keydown={handleEscKeyClose} />
<div class="fixed z-10 inset-0 overflow-y-auto" role="dialog" aria-modal="true">
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    transition:fly|local={{y: 20}}
  >
    <div
      class="fixed inset-0 bg-black bg-opacity-80 transition-opacity"
      aria-hidden="true"
      in:fade|local={{duration: 200}}
      out:fade|local={{duration: 100}}
    />

    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >

    <div
      class="inline-block align-bottom bg-white dark:bg-[#151515] dark:border dark:border-[#808080] text-charcoal dark:text-[#d4d4d4] rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
    >
      <div class="p-8 flex flex-col">
        <h3
          class="text-lg text-center leading-6 font-medium text-red-500"
          id="modal-title"
        >
          You don't have access to this application.
        </h3>
        <!-- svelte-ignore a11y-autofocus -->
        <button
          type="button"
          autofocus
          class="h-9 group flex items-center justify-center relative btn-background-border-none mt-4"
          on:click={copy}
        >
          <span>{clientId}</span>

          <div class="inline-flex items-center absolute right-2" id="client-id">
            <div class="relative inline-flex items-center">
              {#if copiedTooltip}
                <span
                  class="absolute bottom-6 -left-5 text-sm bg-green-500 p-1 rounded-md text-charcoal"
                  transition:fly={{y: 10}}
                >
                  Copied!
                </span>
              {/if}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3"
                  d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
            </div>
          </div>
        </button>
        <div class="flex flex-col md:flex-row items-center justify-around mt-6">
          <!-- svelte-ignore a11y-autofocus -->
          <button
            on:click={() => dispatch('ok')}
            autofocus
            class="w-full md:w-2/5 btn-background h-9">OK</button
          >
        </div>
      </div>
    </div>
  </div>
</div>
